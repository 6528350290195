<template>
  <div class="dark:bg-muted-900 bg-white  flex flex-wrap ">
    <div class="mx-auto w-full max-w-7xl px-4">
      <div class="mx-auto w-full max-w-6xl">
        <div class="bg-primary-900 dark:bg-primary-900/40 ltablet:grid ltablet:grid-cols-2 ltablet:gap-4 overflow-hidden rounded-3xl shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div class="ltablet:py-28 ltablet:pr-0 flex items-center px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-28 lg:pr-0 xl:px-20">
            <div class="ltablet:self-center font-sans lg:self-center">
              <h2 class="text-3xl font-bold text-white sm:text-3xl">
                <span class="block">Ready to dive in?</span>
                <span class="block">Explore all available features.</span>
              </h2>
              <p class="mt-4 text-sm leading-6 text-white">We built a great system to help you get started. Each feature has its own right to exist.</p>
              <div class="mt-8 flex items-center gap-2">
                <BaseButton to="/auth/signup" shape="curved" class="!h-11">Signup Now</BaseButton>
              </div>
            </div>
          </div>
          <div class="aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 -mt-6">
            <NuxtImg class="ltablet:translate-y-20 block translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20 dark:hidden" src= "/img/apps/tairo-layout-collapse-circular-menu.png" alt="Accomium" format="webp" width="568" height="532" loading="lazy" decoding="async" />
            <NuxtImg class="ltablet:translate-y-20 hidden translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20 dark:block" src="/img/apps/tairo-layout-collapse-circular-menu-dark.png" alt="Accomium" format="webp" width="568" height="532" loading="lazy" decoding="async" />
            <SendMessage  @update="openBox"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
const emit = defineEmits(['change', 'update'])

function openBox() {
  emit('update', 1)
}
</script>
