<script setup lang="ts">
const show = ref(false);

function changeShow() {
  show.value = true;
}

definePageMeta({
  title: 'Accomium',
  layout: 'empty',
  description:
    'SME Accounting System.',
  breadcrumb: {
    label: 'accomium',
  },
})

</script>


<template>
  <div>
    <LandingNavbar />
    <LandingHero />
    <LandingLayers />
    <LandingCta @update="changeShow" />
    <LandingFooter />
  </div>
</template>
