<template>
  <svg
    id="stock-chart-1"
    data-vivus
    viewBox="0 0 250 60"
    width="250"
    height="90"
  >
    <path
      d="M 209.328 17.34 C 221.956 17.588 235.264 32.599 250 22.328"
      fill="none"
      vector-effect="non-scaling-stroke"
      stroke-width="2"
      stroke="currentColor"
      stroke-linejoin="miter"
      stroke-linecap="round"
      stroke-miterlimit="3"
    />
    <linearGradient
      id="_lgradient_1"
      x1="0%"
      y1="50%"
      x2="100%"
      y2="50%"
    >
      <stop
        offset="0%"
        stop-opacity="1"
        style="stop-color: rgb(204, 0, 0)"
      />
      <stop
        offset="100%"
        stop-opacity="1"
        style="stop-color: rgb(219, 114, 114)"
      />
    </linearGradient>
    <path
      d=" M 0 43.634 C 5.934 43.634 11.318 51.209 17.462 51.342 C 33.219 51.683 30.603 59.567 39.187 59.868 C 46.963 60.141 50.44 44.192 60.537 43.77 C 69.126 43.77 72.129 52.461 79.739 52.433 C 90.904 52.433 94.93 38.455 106.648 39.78 C 129.082 42.317 124.556 27.606 139.157 27.177 C 153.758 26.747 158.235 44.485 171.96 44.725 C 196.438 45.155 189.782 17.1 208.248 17.1"
      fill="none"
      vector-effect="non-scaling-stroke"
      stroke-width="2"
      stroke="url(#_lgradient_1)"
      stroke-linejoin="miter"
      stroke-linecap="round"
      stroke-miterlimit="3"
    />
    <path
      d="M 206.649 17.218 C 206.649 15.739 207.85 14.538 209.328 14.538 C 210.807 14.538 212.008 15.739 212.008 17.218 C 212.008 18.696 210.807 19.897 209.328 19.897 C 207.85 19.897 206.649 18.696 206.649 17.218 Z"
      fill="rgb(204, 0, 0)"
    />
    <text
      transform="matrix(1,0,0,1,195,5)"
      stroke="none"
      fill="rgb(204, 0, 0)"
    >
      +14%
    </text>
  </svg>
</template>
